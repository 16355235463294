.paginacao {
    display: table;
    width: 100%;
    margin-bottom: 40px;
}

.wp-pagenavi {
    display: table;
    width: auto;
    margin:auto;
    .pages {
        -webkit-border-radius: 0;
        border-radius: 7px;
        border: $cinza 1px solid;
        background-color: #FFF;
        padding: 7px 15px;
        margin-right: 3px;
        color: $cinza;
    }
    .current {
        -webkit-border-radius: 0;
        border-radius: 7px;
        padding: 7px 15px;
        margin-right: 3px;
        color: #fff;
        background-color: $cinza;
        border: $cinza 1px solid;
        text-transform: uppercase;
    }
    .extend {
        -webkit-border-radius: 0;
        border-radius: 7px;
        padding: 7px 15px;
        margin-right: 3px;
        background-color: #FFF;
        border: $cinza 1px solid;
        text-transform: uppercase;
        color: $cinza;
    }
    a {
        -webkit-border-radius: 0;
        border-radius: 7px;
        padding: 7px 15px !important;
        margin-right: 3px;
        color: $cinza;
        background-color: #FFF;
        border: $cinza 1px solid;
        text-transform: uppercase;
        &:hover {
            color: #fff;
            background-color: $cinza;
            border-color: $cinza;
            text-decoration: none;
        }
    }
}