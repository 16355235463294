.btn {
    &.btn-blue {
        position: relative;
        font-family: $existence;
        background-color: $salmao;
        border-radius: 10px;
        font-size: 16px;
        line-height: 20px;
        color: $azul;
        padding: 10px 35px;
        text-align: center;
    }
    &:hover {
        background-color: $azul;
        color: $salmao;
        cursor: pointer;
    }
}
