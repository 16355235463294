.servicos {
    padding: 100px 0 80px 0;
    @include breakpoint(768px, max) {
        padding: 30px 0;
    }
    h1 {
        position: relative;
        font-family: $existence;
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 10px;
        padding: 0;
        text-align: center;
        color: #292b2a;
    }
    .texto {
        display: block;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto 90px;
        color: #292b2a;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
    }
    h2 {
        font-family: $existence;
        font-size: 28px;
        line-height: 40px;
        margin-bottom: 40px;
        padding: 0;
        text-align: center;
        color: #292b2a;
    }
    .servico {
        max-width: 250px;
        display: block;
        margin: auto;
    }
}