select {
    &.form-control {
        position: relative;
        border-radius: 0;
        background-color: #fff;
        border: #E1E1E1 1px solid;
        box-shadow: none;
        font-family: $fontDefault;
        font-size: 16px;
        color: $azul;
        font-weight: 400;
        padding: 16px 24px;
        height: 52px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-image: url($img + 'geral/select.svg');
        background-position: 90% center;
        background-repeat: no-repeat;
        margin-bottom: 12px;
    }
}