.modalWhatsApp {
    position: fixed;
    left: 20px;
    bottom: 40px;
    width: 60px;
    height: 60px;
    background-color: green;
    border-radius: 50%;
    i {
        color: #ffffff;
        text-align: center;
        font-size: 38px;
        padding: 9px 14px;
    }
    &.desktop {
        @include breakpoint(768px, max) {
            display: none;
        }
    }
    &.mobile {
        display: none;
        @include breakpoint(768px, max) {
            display: block;
        }
    }
}