.fancybox-overlay {
    background-image: url($img + 'fancybox/fancybox_overlay.png');
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url($img + 'fancybox/fancybox_sprite.png');
}

#fancybox-loading div {
    background-image: url($img + 'fancybox/fancybox_loading.gif');
}

.fancybox-nav {
    background: transparent url($img + "fancybox/blank.gif");
}