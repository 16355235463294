.contato {

    display: block;
    width: 100%;
    height: auto;
    background-image: url($img + 'contato/pattern.jpg');
    background-repeat: no-repeat;
    background-origin: center top;
    padding: 140px 0 50px;

    @include breakpoint(768px, max) {
        padding-top: 180px;
    }

    h1 {
        color: $salmao;
        font-family: $existence;
        font-size: 64px;
        line-height: 65px;
        text-align: center;
        display: block;
        margin: 20px auto 10px;
        padding: 0;
    }

    h2 {
        text-transform: uppercase;
        font-size: 18px;
        line-height: 26px;
        color: #666666;
        margin-bottom: 0;
        &.mb30 {
            margin-bottom: 30px;
        }
    }

    .dados {
        font-size: 18px;
        line-height: 24px;
        color: #666666;
        margin-bottom: 20px;

        a {
            font-size: 20px;
            line-height: 26px;
            color: #666666;
        }
    }

    .redes {
        ul {
            margin: 0;
            padding: 0;
            @include breakpoint(768px, max) {
                margin-top: 30px;
            }
            li {
                display: inline-block;
                list-style-type: none;
                a {
                    margin-right: 10px;
                    &:hover {
                        text-decoration: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }


}