.form-group {
    margin-bottom: 8px;
}

.form-control {
    font-family: $existence;
    border-radius: 8px;
    background-color: #FFF;
    border: $salmao 1px solid;
    box-shadow: none;
    font-size: 16px;
    line-height: 16px;
    color: $azul;
    font-weight: 400;
    padding: 12px;
    height: 44px;
}