footer {
    background-color: $azul;
    padding: 45px 0;
    .copyright {
        color: $roxo;
        font-family: $fontDefault;
        font-size: 16px;
        line-height: 16px;
        @include breakpoint(768px, max) {
            font-size: 11px;
            text-align: center;
            margin-bottom: 30px;
        }
    }
    .text-right {
        @include breakpoint(768px, max) {
            text-align: center !important;
        }
    }
    a {
        margin-left: 40px;
        @include breakpoint(768px, max) {
            margin: 0 20px;
        }
    }
}
