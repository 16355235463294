header {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;

    .colorBar {
        display: block;
        width: 100%;
        height: auto;
        background-color: #fff;
        padding: 20px 0;
    }

    .logo {
        margin: 8px 0;
        img {
            transition: all .3s ease-in-out;
        }
        &.mobile {
            position: relative;
            left: 36%;
            transform: translateX(-100%);
        }
    }

    .desktop {
        @include breakpoint(768px, max) {
            display: none;
        }
    }
    .mobile {
        display: none;
        @include breakpoint(768px, max) {
            display: block;
        }
    }

    &.active {
        .logo {
            img {
                max-width: 125px;
            }
        }
    }

}