/*!
Theme Name: Renata Roff
Theme URI: http://www.agenciadominio.com.br/
Description: Site desenvolvido especificamente para uso da Renata Roff;
Author: Ag&ecirc;ncia Dom&iacute;nio
Author URI: http://www.agenciadominio.com.br/
Version: 1.0
*/

// Imports
@import "mixins/mixins";
@import "commom/imports";
@import "commom/variables";
@import "commom/fonts";
@import "commom/default";
@import "commom/padroes";
@import "commom/loader";
@import "commom/compartilhe";
@import "commom/titulo";
@import "commom/whatsapp";
@import "commom/fancybox";

//Components
@import "components/buttons";
@import "components/select";
@import "components/inputs";
@import "components/h1";
@import "components/paginacao";

//Layout Site
@import "header";
@import "menu";
@import "vitrine";
@import "sobre";
@import "eventos";
@import "fotosEventos";
@import "videos";
@import "servicos";
@import "clientes";
@import "contato";
@import "footer";