.sobre {
    padding: 180px 0 80px 0;
    display: block;
    width: 100%;
    height: auto;
    background-color: #f6f6f9;

    .container {
        // max-width: 940px;
        .foto {
            @include breakpoint(768px, max) {
                display: block;
                text-align: center;
            }
        }
        h1 {
            font-family: $existence;
            font-size: 64px;
            line-height: 64px;
            padding: 0;
            text-align: center;
            color: $salmao;
            margin: 50px 0 40px;
            @include breakpoint(768px, max) {
                margin: 30px 0;
            }
        }
        .texto {
            p {
                color: $roxo;
                font-size: 16px;
                line-height: 30px;
                margin-bottom: 20px;
            }
        }
    }

}