.eventos {
    padding: 30px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    width: 100%;
    height: 100vh;
    h1 {
        display: flex;
        flex-direction: column;
        width: auto;
        font-family: $existence;
        font-size: 120px;
        line-height: 130px;
        padding: 10px;
        text-align: center;
        color: #FFFFFF;
        margin: auto;
        background-color: rgba($salmao, 0.8);
        @include breakpoint(768px, max) {
            font-size: 20vw;
            line-height: 20vw;
        }
    }
}

.eventosContent {
    padding: 80px 0;
    @include breakpoint(768px, max) {
        padding: 30px 0;
    }
    h1 {
        position: relative;
        font-family: $existence;
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 10px;
        padding: 0;
        text-align: center;
        color: #292b2a;
    }
    .texto {
        display: block;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto 90px;
        color: #292b2a;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
    }
    .listaEventos {
        max-width: 1100px;
        display: flex;
        margin: auto;
        a {
            .evento {
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                display: flex;
                width: 100%;
                height: auto;
                min-height: 255px;
                @include breakpoint(768px, max) {
                    margin-bottom: 30px;
                }
                h2 {
                    display: flex;
                    flex-direction: column;
                    width: auto;
                    font-family: $existence;
                    font-size: 55px;
                    line-height: 65px;
                    padding: 10px;
                    text-align: center;
                    color: #FFFFFF;
                    margin: auto;
                    background-color: rgba($salmao, 0.8);
                    @include breakpoint(768px, max) {
                        font-size: 12vw;
                        line-height: 12vw;
                    }
                }
            }
            &:hover {
                text-decoration: none;
                .evento {
                    h2 {
                        background-color: #292b2a;
                    }
                }
            }
        }
    }
}