.compartilhe {
    margin-top: 0px;
    h3 {
        font-weight: 300;
        text-transform: uppercase;
        color: $cinza;
        font-size: 14px;
    }
    ul {
        display: table;
        width: auto;
        li {
            display: inline-block;
            list-style-type: none;
            width: 55px;
            max-width: 90px;
            a {
                font-size: 20px;
                border-radius: 5px;
                color: #FFF;
                width: 50px;
                display: inline-block;
                text-align: center;
                padding: 10px;
                &.linkFacebook {
                    background-color: #3b5998;
                }
                &.linkTwitter {
                    background-color: #1da1f2;
                }
                &.linkPinterest {
                    background-color: #cb2027;
                }
                &.linkGooglePlus {
                    background-color: #dc4e41;
                }
                &.linkWhats {
                    background-color: #2BB527;
                }
                &.linkEmail {
                    background-color: #848484;
                }
                .fa-inverse {
                    font-size: 18px;
                }
                &:hover {
                    background-color: $vermelhoHover;
                }
            }
        }
    }
}