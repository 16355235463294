.fotosEventos {
    padding: 80px 0;
    @include breakpoint(768px, max) {
        padding-bottom: 30px;
    }
    h1 {
        font-family: $existence;
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 10px;
        padding: 0;
        text-align: center;
        color: $azul;
    }
    .texto {
        display: block;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto 30px;
        color: $roxo;
        font-size: 16px;
        line-height: 30px;
        text-align: left;
        font-weight: 300;
    }
    .carouselFotos {
        max-width: 1000px;
        margin: auto;
        position: relative;
		.item {
			position: relative;
			width: 100%;
            height: 540px;
            background-position: center center;
            background-size: cover;
            @include breakpoint(768px, max) {
                height: auto;
            }
        }
        .owl-nav {
            position: absolute;
            width: 100%;
            top: 42%;
            @include breakpoint(768px, max) {
                top: 26%;
            }
            // transform: translateX(50%);
            // @include breakpoint(768px, max) {
            //     top: auto;
            //     bottom: 80px;
            // }
            button {
                position: absolute;
                display: table;
                width: 50px;
                height: 100px;
                background-repeat: no-repeat;
                background-position: center center;
                transition: all .3s ease;
                @include breakpoint(768px, max) {
                    width: 40px;
                }
                span {
                    display: none;
                }
                &.owl-prev {
                    background: $salmao url($img + 'fotos/seta-left.png') no-repeat center center;
                    left: -10px;
                    @include breakpoint(768px, max) {
                        left: 0;
                    }
                }
                &.owl-next {
                    background: $salmao url($img + 'fotos/seta-right.png') no-repeat center center;
                    right: -10px;
                    @include breakpoint(768px, max) {
                        right: 0;
                    }
                }
                &:hover {
                    outline: none;
                    &.owl-prev {
                        background: $azul url($img + 'fotos/seta-left.png') no-repeat center center;
                    }
                    &.owl-next {
                        background: $azul url($img + 'fotos/seta-right.png') no-repeat center center;
                    }
                }
            }
        }
        .owl-dots {
            position: absolute;
            bottom: 0;
            right: 40px;
            @include breakpoint(768px, max) {
                top: auto;
                bottom: 80px;
            }
            text-align: center;
            margin: 30px 0;
            button {
                &.owl-dot {
                    border: #FFF 2px solid;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    margin: 10px;
                    &.active {
                        border-color: $azul;
                        background-color: $azul;
                    }
                }
            }
        }
    }
}