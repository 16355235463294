h1 {
    display: table;
    width: 100%;
    color: #ffffff;
    font-family: $fontDefault;
    font-size: 72px;
    line-height: 72px;
    padding: 20px 0;
    margin: 0;
    @include breakpoint(768px, max) {
        font-size: 40px;
        line-height: 40px;
    }
    &.right {
        text-align: right;
    }
}