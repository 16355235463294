.backMenu {
    background-color: rgba(#fff, 0.9);
}
.menu {
    // text-align: right;
    // margin-top: 40px;
    // margin-right: 60px;
    .botaoResponsivo {
        width: 100px;
        height: 50px;
        position: absolute;
        right: 35px;
        display: none;
        margin-top: 0;
        top: -25px;
        z-index: 9999;
        @include breakpoint(768px, max) {
            display: inline-block;
        }
        @include breakpoint('320px', 'max') {
            width: 75px;
        }
        .nomemr {
            float: left;
            color: $corBarraResponsivo;
            font-size: 14px;
            text-transform: uppercase;
            margin-top: 15px;
            margin-right: 10px;
            @include breakpoint('320px', 'max') {
                font-size: 10px;
            }
        }
        .linhasmr {
            float: right;
            margin-top: 8px;
            .b1 {
                top: 11px;
            }
            .b2 {
                top: 20px;
            }
            .b3 {
                top: 29px;
            }
            >span {
                background-color: $corBarraResponsivo;
                height: 4px;
                right: 0;
                position: absolute;
                top: 0;
                transition: all 0.3s ease 0s;
                width: 40px;
            }
            &.ativo {
                .b1 {
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    top: 21px;
                    right: 0;
                }
                .b2 {
                    width: 0px;
                }
                .b3 {
                    transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    top: 21px;
                    right: 0;
                }
            }
        }
    }
    nav {
        display: inline-block;
        transition: all .4s ease-in-out;
        @include breakpoint(768px, max) {
            width: 100vw;
            display: block;
            position: absolute;
            z-index: 999;
            background-color: #fff;
            left: -120vw;
            top: -63px;
            height: 100vh;
        }
        ul {
            margin: 0;
            @include breakpoint(768px, max) {
                padding: 30px;
                margin: 80px 0 0;
                
                display: block;
                overflow: auto;
            }
            li {
                list-style-type: none;
                display: inline-block;
                @include breakpoint(768px, max) {
                    display: block;
                }
                a {
                    position: relative;
                    z-index: 2;
                    display: block;
                    color: $azul;
                    font-family: $existence;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: center;
                    text-transform: uppercase;
                    padding: 35px 25px;
                    @include breakpoint(768px, max) {
                        line-height: 30px;
                        padding: 20px 0;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        width: 90%;
                        height: 155%;
                        background-color: #f9d3bf;
                        top: -235%;
                        left: 10%;
                        transform: rotate(10deg);
                        transition: all .3s ease-in-out;
                        @include breakpoint(768px, max) {
                            display: none;
                        }
                    }

                    @include breakpoint(768px, max) {
                        display: block;
                        max-width: initial;
                        color: $azul;
                        font-size: 30px;
                        line-height: 40px;
                    }
                    &:hover {
                        text-decoration: none;
                        color: $azul;
                        &::after {
                            top: -60px;
                        }
                    }
                }
                &.active {
                    a {
                        &::after {
                            top: -60px;
                        }
                    }
                }
            }
        }
        &.ativo {
            left: 0px;
        }
    }
}

.subMenu {
    ul {
        margin: 0;
        padding: 0;
        li {
            display: inline-block;
            list-style-type: none;
            a {
                position: relative;
                z-index: 2;
                color: #f9d3bf;
                font-family: $existence;
                font-size: 24px;
                line-height: 24px;
                text-transform: uppercase;
                margin-left: 8px;
                &.linka {
                    margin-right: 30px;
                    margin-left: 0;
                    padding: 0 25px;
                    &::after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        width: 100%;
                        height: 100px;
                        background-color: #f9d3bf;
                        top: -200px;
                        left: 10%;
                        transform: rotate(10deg);
                        transition: all .3s ease-in-out;
                    }
                }
                &:hover {
                    text-decoration: none;
                    color: $azul;
                    &.linka {
                        &::after {
                            top: -60px;
                        }
                    }
                }
            }
            &.active {
                a {
                    &.linka {
                        color: $azul;
                        &::after {
                            top: -60px;
                        }
                    }
                }
            }
        }
    }
}