.vitrine {
    position: relative;
    z-index: 1;
    display: block;
    width: 100%;
    height: auto;
    margin: 185px 0 0;
    @include breakpoint(768px, max) {
        margin-top: 105px;
    }
    .carousel {
		.item {
			position: relative;
			width: 100vw;
            height: 535px;
            background-position: center center;
        }
        .owl-nav {
            position: absolute;
            width: 100%;
            top: 45%;
            @include breakpoint(768px, max) {
                top: auto;
                bottom: 80px;
            }
            button {
                position: absolute;
                display: table;
                width: 40px;
                height: 40px;
                background-repeat: no-repeat;
                background-position: center center;
                span {
                    display: none;
                }
                &.owl-prev {
                    background-image: url($img + 'vitrine/seta-left.svg');
                    left: 30px;
                    @include breakpoint(768px, max) {
                        left: 65vw;
                    }
                }
                &.owl-next {
                    background-image: url($img + 'vitrine/seta-right.svg');
                    right: 30px;
                }
            }
        }
        .owl-dots {
            position: absolute;
            bottom: 0;
            right: 40px;
            @include breakpoint(768px, max) {
                top: auto;
                bottom: 80px;
            }
            text-align: center;
            margin: 30px 0;
            button {
                &.owl-dot {
                    border: $salmao 2px solid;
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                    margin: 10px;
                    &.active {
                        border-color: $salmao;
                        background-color: $salmao;
                    }
                }
            }
        }
    }
}