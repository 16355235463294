.videos {
    padding: 180px 0 80px 0;
    @include breakpoint(768px, max) {
        padding: 30px 0;
    }
    h1 {
        font-family: $existence;
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 10px;
        padding: 0;
        text-align: center;
        color: $azul;
    }
    .texto {
        display: block;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto 30px;
        color: $roxo;
        font-size: 16px;
        line-height: 30px;
        text-align: center;
    }
    .listaVideos {
        .container {
            max-width: 850px;
            a {
                display: block;
                width: 100%;
                height: auto;
                margin-bottom: 15px;
            }
        }
    }
    .maisVideos {
        display: block;
        max-width: 850px;
        text-align: right;
        a {
            display: inline-block;
            font-size: 16px;
            line-height: 20px;
            text-align: right;
            color: $salmao;
            &:hover {
                text-decoration: none;
                color: $azul;
            }
        }
    }
}