@import url('https://fonts.googleapis.com/css?family=Lato');

@font-face {
     font-family: 'Existence';
     src: url($fontsSRC + 'Existence-Light.eot');
     src: url($fontsSRC + 'Existence-Light.eot?#iefix') format('embedded-opentype'),
          url($fontsSRC + 'Existence-Light.woff2') format('woff2'),
          url($fontsSRC + 'Existence-Light.woff') format('woff'),
          url($fontsSRC + 'Existence-Light.ttf') format('truetype'),
          url($fontsSRC + 'Existence-Light.svg#Existence-Light') format('svg');
     font-weight: 300;
     font-style: normal;
}